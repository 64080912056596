.notice-list .section {
	padding-top: 10vh;
	padding-bottom: 30vh;
}

.notice-list .title {
	margin-bottom: 0.25em;
}

.notice-list hr {
	width: 5em;
	margin: 0;
	margin-bottom: 3em;
	background-color: #BB162B
}

table td.notice-id {
	width: 6em;
	text-align: center;
}

table td.notice-title {
	font-weight: bold;
}

table td.notice-time {
	width: 8em;
	text-align: center;
}
