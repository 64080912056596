.youtube {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.youtube-video {
	width: 100%;
	flex: 16 0 auto;
	max-width: 960px;
	position: relative;
}

.youtube-video::after {
	display: block;
	padding-bottom: 56.25%;
	content: " ";
}

.youtube-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.youtube-chat {
	flex: 9 0 auto;
	position: relative;
	max-width: 540px;
}

.youtube-chat::after {
	display: block;
	padding-bottom: 100%;
	content: " ";
}

.youtube-chat iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (max-width: 1024px) {
	.youtube {
		flex-direction: column;
	}

	.youtube-video {
		width: 100%;
	}

	.youtube-chat {
		width: 100%;
	}

	.youtube-chat::after {
		padding-bottom: 400px;
	}
}

.youtube-link {
	border-color: #BB162B;
	color: #BB162B;
}

.youtube-link-icon {
	margin-right: 0.25em;
}
