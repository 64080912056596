.root .modal-content {
	max-width: 450px;
	padding: 5vh 4vw;
	background-color: white;
	border-radius: 3px;
}

.modal-content .title {
	text-align: center;
}

.modal-content .field .control .button {
	border-color: #BB162B;
	color: #BB162B;
}

.modal-content .field .control .button:hover {
	background-color: #BB162B;
	color: white;
}
