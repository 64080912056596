.notice-view .title {
	margin-bottom: 0.25em;
}

.notice-view hr {
	width: 5em;
	margin: 0;
	margin-bottom: 3em;
	background-color: #BB162B
}

.notice-view-body {
	border-top: 2px solid #555555;
	border-bottom: 2px solid #555555;
}

.notice-view-title {
	display: flex;
	padding: 0.5em 20px;
	border-bottom: 2px solid #9F9F9F;
}

.notice-view-title-text {
	flex: 1 1 auto;
	font-size: 13px;
	font-weight: bold;
}

.notice-view-title-date {
	font-size: 12px;
}

.notice-view-description {
	font-size: 13px;
	padding: 40px 10px;
	white-space: pre-wrap;
}

.notice-view-buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 2em;
}

.notice-view-buttons .button {
	border-radius: 0;
	border: none;
	background-color: #666666;
	color: white;
}
