@font-face {
	font-family: 'NanumBarunGothic';
	src: url(./assets/nanumbarungothic.woff);
}

body {
	margin: 0;
	background-color: #FBFBFB;
	color: #333333;
	font-family: "NanumBarunGothic", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "NanumBarunGothic", monospace;
}

a {
	color: #333333;
}

a:hover {
	color: #BB162B;
}

.custom-box {
	padding: 40px 30px;
	background-color: white;
}

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2em 0;
	width: 100%;
	color: #BBBBBB;
}

table {
	width: 100%;
}

table thead {
	border-top: 2px solid #555555;
}

table thead th {
	padding: 0.5em 0;
	text-align: center !important;
	font-size: 12px;
	color: #666666;
}

table tr {
	border-bottom: 1px solid #C3C3C3;
}

td {
	padding: 0.5em 0;
	font-size: 13px;
}

li {
	list-style: none;
}
