.hero-body {
	min-height: 500px;
	background-image: url("/banner.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

@media (max-width: 1024px) {
	.hero-body {
		min-height: 400px;
	}
}

@media (max-width: 768px) {
	.hero-body {
		min-height: 200px;
	}
}
