header {
	width: 100%;
}

.navbar-brand img {
	min-height: 2em;
}

.navbar-mobile-buttons {
	display: flex;
	align-items: stretch;
	margin-left: auto;
}

.navbar-mobile-button {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0.5em;
}

.navbar-mobile-button:hover {
	color: #BB162B;
}

.navbar-menu a.navbar-item {
	padding-left: 30px;
	padding-right: 30px;
}

.navbar-menu a.navbar-item:hover {
	background-color: white;
	color: #BB162B;
}

.navbar-menu a.navbar-item:focus {
	background-color: white;
	color: #4A4A4A;
}

.navbar-menu .button {
	border-color: #BB162B;
	color: #BB162B;
}

.navbar-menu .button:hover {
	background-color: #BB162B;
	color: white;
}

.header-mobile-menu {
	display: flex;
	flex-direction: column;
}

.header-mobile-menu a {
	width: 100%;
	text-align: center;
	background-color: white;
	padding: 0.5em;
}

.header-mobile-menu a:hover {
	color: #BB162B;
}
