.lecture-list .section {
	padding-top: 10vh;
	padding-bottom: 30vh;
}

.lecture-list .title {
	margin-bottom: 0.25em;
}

.lecture-list hr {
	width: 5em;
	margin: 0;
	margin-bottom: 3em;
	background-color: #BB162B
}

.lecture-not-authorized {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 1em 0;
}

table .lecture-id {
	width: 6em !important;
	text-align: center !important;
}

table .lecture-title {
	font-weight: bold !important;
}

table .lecture-time {
	width: 8em !important;
	text-align: center !important;
}
