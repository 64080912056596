.home-lecture .title {
	font-size: 18px;
	margin-bottom: 0.5em;
}

.home-lecture hr {
	width: 3em;
	margin: 0;
	margin-bottom: 1.5em;
	background-color: #BB162B
}

.home-lecture .media .content {
	display: flex;
	flex-direction: column;
	white-space: pre-wrap;
}

.home-lecture .media .content strong {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

@media (max-width: 768px) {
	.home-lecture .media {
		flex-direction: column;
		align-items: center;
	}

	.home-lecture .media-left {
		margin: 0;
	}

	.home-lecture .media .content {
		align-items: center;
	}
}

img.home-lecture-thumbnail {
	width: 128px;
	height: 128px;
}

.home-lecture-not-authorized {
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-lecture-list {
	display: flex;
	flex-direction: column;
}

.home-lecture-item {
	display: flex;
	font-size: 12px;
}

span.home-lecture-title {
	flex: 1 1 auto;
	padding: 0.25em 0;
	font-weight: bold;
}
